@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Kaushan+Script|Oswald|Teko');

@font-face {
  font-family: meat;
  src: url('./assets/meat_buckets.ttf');
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Oswald', sans-serif; */

  background-color: #000;
  outline: none;
}

.underline{
  text-decoration: underline;

}


.aboutText{
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;

}

.aboutTextText{
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  border-radius: 0px;
  text-decoration: none;
  padding: 12px 18px;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.meatBuckets {
  font-family: meat;
  font-family: 'Courier';
}

/* Navbar */
nav {
  background-color: #000;
  padding: 5px 20px;
  font-family: 'Courier';
}

.typewriterHeading {
  font-family: 'Courier';
  font-size: xx-large;
}

ul {
  list-style-type: none;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.logo a:hover {
  text-decoration: none;
}

.menu li {
  font-size: 16px;
  padding: 15px 5px;
  white-space: nowrap;
}

.logo a,
.toggle a {
  font-size: 20px;
}

.button.secondary {
  border-bottom: 1px #444 solid;
}

/* Mobile menu */
.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.toggle {
  order: 1;
}

.item.button {
  order: 2;
}

.item {
  width: 100%;
  text-align: center;
  order: 3;
  display: none;
}

.item.active {
  display: block;
}

/* Tablet menu */
@media all and (min-width: 600px) {
  .menu {
    justify-content: center;
  }

  .logo {
    flex: 1;
  }

  .toggle {
    flex: 1;
    text-align: right;
  }

  .item.button {
    width: auto;
    order: 1;
    display: block;
  }

  .toggle {
    order: 2;
  }

  .button.secondary {
    border: 0;
  }

  .button a {
    padding: 7.5px 15px;
    background: teal;
    border: 1px #006d6d solid;
  }

  .button.secondary a {
    background: transparent;
  }

  .button a:hover {
    text-decoration: none;
  }

  .button:not(.secondary) a:hover {
    background: #006d6d;
    border-color: #005959;
  }

  .button.secondary a:hover {
    color: #ddd;
  }
}

/* Desktop menu */
@media all and (min-width: 900px) {
  .item {
    display: block;
    width: auto;
  }

  .toggle {
    display: none;
  }

  .logo {
    order: 0;
  }

  .item {
    order: 1;
  }

  .button {
    order: 2;
  }

  .menu li {
    padding: 15px 10px;
  }

  .menu li.button {
    padding-right: 0;
  }
  .meatBuckets {
    display: none;
  }

  .tyngreNinja {
  }
}

/* Landingpage */

.socialMediaContainer {
  color: white;
}

.icon {
  margin: 5px;
}

.ladningbg {
  height: 100vh;
}

.sectionContainerLanding {
  width: 100%;
  padding: 0 7%;
  display: table;
  margin: 0;
  max-width: none;
  background-color: #000;
  height: 100vh;
}

.innerContainerLandingPage {
  margin: 0;
  max-width: none;
  background-color: #000;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sectionContainer {
  width: 100%;
  padding: 0 7%;
  display: table;
  background-color: #000000;
  height: 100vh;
  display: flex;
}

.innerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.projectsContainer {
  width: 100%;
  padding: 0 7%;
  display: table;
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: #f8f9fa !important;
}

.responsive {
  max-width: auto;
  height: 50%;
  margin-right: 5%;
}

.projectDesciptionContainer {
  margin-left: 2%;
}

.projectDesciptionRightContainer {
  margin-right: 2%;
}

.techText {
  font-size: large;
}

.projectImages {
  display: flex;
  flex-direction: column;
}

.projectContainer {
  display: flex;
  max-width: auto;
  width: 1400px;
  min-width: auto;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.projectHeading {
  font-size: x-large;
  margin-bottom: 2%;
}

/* Typing */

.css-typing p {
  color: white;
  border-right: 0.15em solid white;
  font-family: 'Courier';
  font-size: 7vw;
  white-space: nowrap;
  overflow: hidden;
}

.css-typing p:nth-child(1) {
  width: 4.3em;
  -webkit-animation: type 1s steps(40, end);
  animation: type 1s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 9.5em;
  opacity: 0;
  -webkit-animation: type2 1s steps(40, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(3) {
  width: 10.3em;
  opacity: 0;
  -webkit-animation: type3 2s steps(40, end),
    blink 0.5s step-end infinite alternate;
  animation: type3 2s steps(40, end), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 1s;
  animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }

  99.9% {
    border-right: 0.15em solid rgb(255, 255, 255);
  }

  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }

  99.9% {
    border-right: 0.15em solid rgb(255, 255, 255);
  }

  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  99.9% {
    border-right: 0.15em solid rgb(255, 255, 255);
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  99.9% {
    border-right: 0.15em solid rgb(255, 255, 255);
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

/*
 Scroll down section 
 */

#section07 a {
  padding-top: 80px;
}

#section07 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}

#section07 a span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

#section07 a span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

#section07 a span:nth-of-type(3) {
  top: 32px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sdb07 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.demo a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}

.demo a:hover {
  opacity: 0.5;
}

/* Work */

.img {
  max-width: 40%;
  height: 70%;
  margin-right: 5%;
}

.headingText {
  width: 100%;
  margin-bottom: 5%;
  font-size: 3vw;
}

@media (max-width: 600px) {
  .responsive {
    display: none;
  }

  p {
    font-size: small;
    line-height: 1.8;
  }

  .innerContainer {
    margin: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
  }
}

.buttons {
  overflow: hidden;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttons a {
  margin-right: 20px;
  margin-bottom: 20px;
}

a#link {
  position: absolute;
  left: 20px;
  bottom: 10px;
  text-decoration: none;
  color: #555;
  font-weight: bold;
}

/*------------------------------------------------------*/
/* BUTTONS */
/*------------------------------------------------------*/
.btn,
input[type='submit'] {
  cursor: pointer;
  border-radius: 0px;
  text-decoration: none;
  padding: 12px 18px;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-mid {
  border-radius: 0px;
  text-decoration: none;
  padding: 14px 21px;
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:hover,
input[type='submit']:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-white {
  width: 100%;
  border: solid 2px #fff;
  background: transparent;
  color: #fff !important;
}

.btn-white:hover {
  border: solid 2px #fff;
  background: #fff;
  color: #1f1f1f !important;
}

.btn-dark,
input[type='submit'] {
  border: solid 2px #1f1f1f;
  background: transparent;
  color: #1f1f1f;
}

.btn-dark:hover,
input[type='submit']:hover,
.btn-dark.active {
  border: solid 2px #1f1f1f;
  background: #1f1f1f;
  color: #fff;
}

.btn-color {
  background: transparent;
}

.btn-color:hover {
  color: #fff;
}

.desk {
  width: 400px;
  height: 200px;
  background: red;
}

.div-only-mobile {
  width: 400px;
  height: 200px;
  background: orange;
}

@media screen and (max-width: 1920px) {
  .div-only-mobile {
    visibility: hidden;
  }
}

@media screen and (max-width: 906px) {
  .projectContainer {
    display: flex;
    max-width: auto;
    width: auto;
    min-width: auto;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .workButtonsContainer {
    display: flex;
    flex-direction: column;
  }

  .small {
    margin-bottom: 2%;
  }

  .desk {
    visibility: hidden;
  }

  .div-only-mobile {
    visibility: visible;
  }
}

@media screen and (max-width: 1265px) {
  .projectContainer {
    margin-top: 10%;
    display: flex;
    max-width: auto;
    width: auto;
    min-width: auto;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .workButtonsContainer {
    display: flex;
    flex-direction: column;
  }

  .small {
    margin-bottom: 2%;
  }
}
